import { render, staticRenderFns } from "./position.vue?vue&type=template&id=6bd1ee59&scoped=true"
import script from "./position.ts?vue&type=script&lang=ts&external"
export * from "./position.ts?vue&type=script&lang=ts&external"
import style0 from "./position.scoped.scss?vue&type=style&index=0&id=6bd1ee59&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd1ee59",
  null
  
)

export default component.exports